import { StyleSheet } from 'react-native';
import {styles as commonStyles} from '../cmmon/CommonStyle'
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';


 
const styles = StyleSheet.create({
  menu: {
    flexDirection: 'row',
  },
  menuItem: {
    color: '#2464C0',
    fontSize: 15,
    marginHorizontal: wp('1%'),
    fontWeight: '600',
  },
  mobileMenu: {
    position: 'absolute',
    top: 70,
    right: 0,
    width: '100%',
    padding: wp('2%'),
    borderBottomRadius: 10, // Adjust value as needed for 1rem
    backgroundColor: '#fff',
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 25 },
    shadowOpacity: 0.25,
    shadowRadius: 50,
    elevation: 12, // For Android shadow
    zIndex: 1000,
  },
  mobileMenuItem: {
    color: '#2464C0',
    fontSize: 15,
    marginVertical: hp('2%'),
    marginHorizontal: wp('4%'),
    fontWeight: '600',
  },
  subMenu: {
    backgroundColor: '#F2F2F2',
    padding: 15,
    borderRadius: 5,
    position: 'absolute',
    top: 40,
    right: 0,
  },
  subMenuItem: {
    color: '#2464C0',
    fontWeight: '600',
    padding: 5,
    fontSize: 15,
    marginVertical: hp('1%'),
  },
  mobileSubMenu: {
    backgroundColor: '#fff',
    borderRadius: 5,
    padding: wp('2%'),
  },
  mobileSubMenuItem: {
    color: '#2464C0',
    fontWeight: '600',
    padding: 5,
    fontSize: 15,
    marginVertical: hp('1%'),
    marginHorizontal: wp('3%'),
  },
});


export  {commonStyles as commonStyles, styles as styles};

