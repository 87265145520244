import { StyleSheet } from 'react-native';
import { widthPercentageToDP as wp, heightPercentageToDP as hp } from 'react-native-responsive-screen';

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    width: '100%',
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: wp('6%'),
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    borderRadius: 10, // Adjust value as needed for 1rem
    shadowColor: '#2464C0',
    shadowOffset: { width: 0, height: 25 },
    shadowOpacity: 0.25,
    shadowRadius: 50,
    elevation: 12, // For Android shadow
    transition: 'transform 1s ease',
  },
  headerMobile: {
    width: '100%',
    height: 70,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: wp('3%'),
    position: 'fixed',
    top: 0,
    zIndex: 1000,
    borderRadius: 10, // Adjust value as needed for 1rem
    shadowColor: '#2464C0',
    shadowOffset: { width: 0, height: 25 },
    shadowOpacity: 0.25,
    shadowRadius: 50,
    elevation: 12, // For Android shadow
    transition: 'transform 1s ease',
  },
  logo: {
    width: 120,
    height: 60,
    resizeMode:"contain",
  },
  hamburgerMenu: {
    padding: 10,
  },
  hamburgerMenuText: {
    color: '#2464C0',
    fontSize: 28,
  },
  content: {
    marginTop: 70, // To compensate for the fixed header height
    padding: wp('4%'),
    backgroundImage: ['linear-gradient(135deg, #f4dabf 70%, #2464C0 30.1%)'],
    height: '100%',
    zIndex: -1,
  },
});

export {styles};
