import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Home from './web/home/Home';
import Services from './web/services/Services'; // Import other components as needed
import Support from './web/support/Support';
import Career from './web/career/Career';
import ContactUs from './web/contact/ContactUs';
import Template from './web/template/Template';
import reportWebVitals from './reportWebVitals';

const App = () => {
  const [currentScreen, setCurrentScreen] = useState('Home');

  const renderScreen = () => {
    switch (currentScreen) {
      case 'Home':
        return <Home />;
      case 'Services':
        return <Services />;
      case 'Support':
        return <Support />;
      case 'Career':
        return <Career />;
      case 'ContactUs':
        return <ContactUs />;
      default:
        return <Home />;
    }
  };

  return (
    <Template setCurrentScreen={setCurrentScreen}>
      {renderScreen()}
    </Template>
  );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
