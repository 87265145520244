import React, { useState, useEffect, useRef } from 'react';
import { View, Text, TouchableOpacity, Image, ScrollView, useWindowDimensions } from 'react-native';
import { commonStyles, styles } from './TemplateStyle';
import logo from '../../logo.svg';

const Template = ({ setCurrentScreen, children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState(false);
  const { width } = useWindowDimensions();
  const isMobile = width <= 768;
  const loginMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (loginMenuRef.current && !loginMenuRef.current.contains(event.target)) {
      setIsLoginMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isLoginMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLoginMenuOpen]);

  return (
    <View style={commonStyles.container}>
      {/* Header */}
      <View style={isMobile ? commonStyles.headerMobile : commonStyles.header}>
        <TouchableOpacity onPress={() => setCurrentScreen('Home')}>
          <Image
            source={{ uri: logo }} // Replace with your logo URL
            style={commonStyles.logo}
          />
        </TouchableOpacity>
        {isMobile ? (
          <TouchableOpacity onPress={() => setIsMenuOpen(!isMenuOpen)} style={styles.hamburgerMenu}>
            <Text style={commonStyles.hamburgerMenuText}>☰</Text>
          </TouchableOpacity>
        ) : (
          <View style={styles.menu}>
            <TouchableOpacity onPress={() => setCurrentScreen('Home')}><Text style={styles.menuItem}>Home</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => setCurrentScreen('Services')}><Text style={styles.menuItem}>Services</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => setCurrentScreen('Support')}><Text style={styles.menuItem}>Support</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => setCurrentScreen('Career')}><Text style={styles.menuItem}>Career</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => setCurrentScreen('ContactUs')}><Text style={styles.menuItem}>Contact Us</Text></TouchableOpacity>
            <TouchableOpacity onPress={() => setIsLoginMenuOpen(!isLoginMenuOpen)}>
              <Text style={styles.menuItem}>Login</Text>
            </TouchableOpacity>
            {isLoginMenuOpen && (
              <View style={styles.subMenu} ref={loginMenuRef}>
                <TouchableOpacity><Text style={styles.subMenuItem}>Admin</Text></TouchableOpacity>
                <TouchableOpacity><Text style={styles.subMenuItem}>Institute</Text></TouchableOpacity>
                <TouchableOpacity><Text style={styles.subMenuItem}>Parent/Guardian</Text></TouchableOpacity>
              </View>
            )}
          </View>
        )}
      </View>

      {/* Hamburger Menu Dropdown */}
      {isMobile && isMenuOpen && (
        <View style={styles.mobileMenu}>
          <TouchableOpacity onPress={() => setCurrentScreen('Home')}><Text style={styles.mobileMenuItem}>Home</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen('Services')}><Text style={styles.mobileMenuItem}>Services</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen('Support')}><Text style={styles.mobileMenuItem}>Support</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen('Career')}><Text style={styles.mobileMenuItem}>Career</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => setCurrentScreen('ContactUs')}><Text style={styles.mobileMenuItem}>Contact Us</Text></TouchableOpacity>
          <TouchableOpacity onPress={() => setIsLoginMenuOpen(!isLoginMenuOpen)}>
            <Text style={styles.mobileMenuItem}>Login</Text>
          </TouchableOpacity>
          {isLoginMenuOpen && (
            <View style={styles.mobileSubMenu} ref={loginMenuRef}>
              <TouchableOpacity><Text style={styles.mobileSubMenuItem}>Admin</Text></TouchableOpacity>
              <TouchableOpacity><Text style={styles.mobileSubMenuItem}>Institute</Text></TouchableOpacity>
              <TouchableOpacity><Text style={styles.mobileSubMenuItem}>Parent/Guardian</Text></TouchableOpacity>
            </View>
          )}
        </View>
      )}

      {/* Main Content */}

      <ScrollView contentContainerStyle={commonStyles.content}>
        {children}
      </ScrollView>
    </View>
  );
};

export default Template;
