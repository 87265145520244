import React, { useState, useEffect, useRef } from 'react';
import { View, Text, ScrollView } from 'react-native';

const Support = () => {
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState(false);
  const loginMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (loginMenuRef.current && !loginMenuRef.current.contains(event.target)) {
      setIsLoginMenuOpen(false);
    }
  };

  useEffect(() => {
    if (isLoginMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isLoginMenuOpen]);

  return (
    <View>

      {/* Content */}
      Support
    </View>
  );
};

export default Support;
