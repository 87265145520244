import { StyleSheet } from 'react-native';


 
const styles = StyleSheet.create({

});


export default styles;

